import React, { useState, useEffect, Component, useRef } from 'react';
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { LinkContainer } from 'react-router-bootstrap';
import '../assets/stylesheets/MainMenu.scss';
import logo from '../assets/images/eXp-Realty-Logo-Color.svg';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, openModalVideo, selectModal, selectModalVideoUrl, setPricePoint, selectPricePoint, setSubdivision, selectSubdivision, setResultHeader, selectResultHeader, setResultBody, selectResultBody, setResultFooter, selectResultFooter } from '../features/mainStore/mainSlice';
import { useLocation } from 'react-router-dom';
import { usePostFormsMutation } from '../services/forms';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';

function MainMenu() {
    const location = useLocation();
    const [ getWidth, setWidth ] = useState(window.innerWidth);
    const [ showMenu, setShowMenu ] = useState(true);
    const [ formValues, setFormValues ] = useState({cs:0});
    const [ validated, setValidated ] = useState(false);
    const [ postForms, {isLoading, data, isSuccess, isError} ] = usePostFormsMutation();
    const formCalc = useRef(null);
    const currentModal = useSelector(selectModal);
    const modalVideoUrl = useSelector(selectModalVideoUrl);
    const pricePoint = useSelector(selectPricePoint);
    const subdivision = useSelector(selectSubdivision);
    const resultHeader = useSelector(selectResultHeader);
    const resultBody = useSelector(selectResultBody);
    const resultFooter = useSelector(selectResultFooter);

    const Pending = <Badge className='m-badge' text='dark' bg='warning'>Pending</Badge>;
    const Sold = <Badge className='m-badge' bg='danger'>Sold</Badge>;


    const dispatch = useDispatch();    
    window.dispatch = dispatch;
    window.setModal = setModal;

    const htmlFrom = (htmlString) => {
        if(htmlString) {
        //const cleanHtmlString = DOMPurify.sanitize(htmlString,
        //  { USE_PROFILES: { html: true } });
        //const html = parse(cleanHtmlString, {
            const html = parse(htmlString, {
            replace: ({ name, attribs, children }) => {
                if (attribs && attribs['data-react'] === 'true') {
                    const props = attributesToProps(attribs);
                    const {'data-react':dataReact, onclick, ...rest} = props;
                    const reactElements = {Button:Button, 'Modal.Title': Modal.Title};
                    const Type = reactElements[name.replace(/(\b[a-z](?!\s))/g, (c) => c.toUpperCase())];
                    return <Type {...rest} onClick={onclick ? () => (new Function(onclick))() : null}>{domToReact(children)}</Type>;
                }
              }
            });
            return html;
        }
        else return null;
    }

    const handleChange = (event) => {
        const val = event.target.value;
        const id = event.target.id;
        if(event.target.dataset['type'] === 'mce-phone')
        {
            const area = val.indexOf('-') > 0 ? val.split('-')[0] : val.substring(0,3);
            const prefix = val.indexOf('-') > 0 ? val.split('-')[1] : val.substring(3,6);
            const suffix = val.indexOf('-') > 0 ? val.split('-')[2] : val.substring(6,10);
            const newformValues = {
                ...formValues,
                [id.toLowerCase().replace('mce-','')]: val,
                ['phone-area']: area,
                ['phone-detail1']: prefix,
                ['phone-detail2']: suffix,
            };
            setFormValues(newformValues);   
        }
        else if(event.target.dataset['type'] === 'mce')
        {
            const newformValues = {
                ...formValues,
                [id.toLowerCase().replace('mce-','')]: val
            };
            setFormValues(newformValues);   
        }
        else if(id =='neighborhood') {
            const newformValues = {
                ...formValues,
                [id]: val
            };
            setFormValues(newformValues);        
            dispatch(setSubdivision(val));       
        } 
        else if(id =='amount') {
            const newformValues = {
                ...formValues,
                [id]: val,
                payments: ''
            };
            setFormValues(newformValues);        
        } 
        else if(id =='payments') {
            const newformValues = {
                ...formValues,
                [id]: val,
                amount: ''
            };
            setFormValues(newformValues);        
        } 
        else{
            const newformValues = {
                ...formValues,
                [id]: val
            };
            setFormValues(newformValues);               
        }  
        event.target.focus();    
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else
        {
            event.preventDefault();
            event.stopPropagation();
            console.log(form.elements);
            var formCookies = {};
            var formName = form.elements.cta.value.toLowerCase();
            var postUrl = 'form';
            var storeFormData = true;
            for(var x = 0; x < form.elements.length; x++) {
                switch(form.elements[x].id)
                {                   
                    case 'cs':
                        {
                            if(Number(form.elements.ts.value) > Number(form.elements[x].value)+1)
                            {
                                formCookies = {
                                    ...formCookies,
                                    [form.elements[x].id]: Number(form.elements[x].value) + 1
                                };  
                                const newformValues = {
                                    ...formValues,
                                    [form.elements[x].id]: Number(form.elements[x].value) + 1
                                };
                                setFormValues(newformValues);    
                            }
                        }
                        break;                    
                    case 'fullname':
                    case 'fname':
                    case 'lname':
                    case 'mobile':
                    case 'email':
                        {
                            formCookies = {
                                ...formCookies,
                                [form.elements[x].id]: form.elements[x].value
                            };  
                        }
                        break;
                    case 'mce-FNAME':
                    case 'mce-LNAME':
                    case 'mce-EMAIL':
                    case 'mce-PHONE-area':
                    case 'mce-PHONE-detail1':
                    case 'mce-PHONE-detail2':                        
                        {
                            formCookies = {
                                ...formCookies,
                                [form.elements[x].id.toLowerCase().replace('mce-','')]: form.elements[x].value
                            };  
                        }
                        break;                        
                    default:
                        break;
                }         
            }

            switch(formName)
            {
                case 'justsold':
                    {
                        storeFormData = true;
                    }
                    break;                     
                case 'marketnewsletter':
                    {
                        storeFormData = true;
                    }
                    break;                    
                case 'feedback': 
                    {
                        storeFormData = false;
                        postUrl = 'form/feedback';
                    }
                    break;
                default:
                    {
                        storeFormData = true;
                        formName = 'formData';
                    }
                    break;
            }
            if(storeFormData) localStorage.setItem(formName, JSON.stringify(formCookies));
            var canSubmit = Number(form.elements.cs.value) + 1 >= Number(form.elements.ts.value);
            setValidated(canSubmit);
            if(canSubmit) 
            {
                const postDataValues = {
                    ...formValues,
                    neighborhood: form.elements.neighborhood?.value,
                    cs: Number(form.elements.cs.value) + 1,
                    ts: Number(form.elements.ts.value),
                    cta: form.elements.cta.value,
                    sto: form.elements.sto ? form.elements.sto.value : null,
                    url: window.location.href
                };                
                postForms({data:postDataValues, url:postUrl});
            }
        }
        
    }
   
    const Currency = (num) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
          });
        return formatter.format(num)
    }
       
    const CheckForm = (form, type) => {
    
        form.amount.value = form.amount.value.replace(",", "").replace("$", "");
        form.payments.value = form.payments.value.replace(",", "").replace("$", "");
    
        if (type == "payment" && form.amount.value.trim() == "") {
            form.amount.required = true;
            setValidated(true);
            form.checkValidity();
            
            form.amount.focus();
            return false;
        }
        if (type == "amount" && form.payments.value.trim() == "") {
            form.payments.required = true;
            setValidated(true);
            form.checkValidity();

            form.payments.focus();
            return false;
        }
        return true;
    }

    const CalcMortgage = (type) => {
        const form = formCalc.current;
        if(type=='reset')
        {
            form.amount.required = false;
            form.payments.required = false;
            var newformValues = {};
            newformValues = {...formValues, cs: 1, amount: '', payments: '', term: 20, rate: 5.0};
            setFormValues(newformValues);  
            setValidated(false);
        }
        else if (form.checkValidity() === false) {
            setValidated(true);
        }
        else
        {     
            CheckForm(form, type);   
            if (CheckForm(form, type)) {
                var n = form.term.value * 12;
                var r = form.rate.value / 1200;
                var a = form.amount.value;
                var p = form.payments.value;
        
                if (type == "payment") {
                    p = (a * (1200 * r) * Math.pow((1 + r), n)) / (1200 * (Math.pow((1 + r), n) - 1));
                }
                else if (type == "amount") {
                    a = (p / ((1200 * r) * Math.pow((1 + r), n)) * (1200 * (Math.pow((1 + r), n) - 1)));
                }
                form.payments.value = Currency(p);
                form.amount.value = Currency(a);
            }
        }
    }

    useEffect(() => {
        if((new URLSearchParams(location.search)).get('displayForm'))
        {
            dispatch(setModal((new URLSearchParams(location.search)).get('displayForm')));
        }
        if((new URLSearchParams(location.search)).get('frm'))
        {
            if((new URLSearchParams(location.search)).get('frm').toLowerCase() == 'vendorbook')
            {
                if(window.location.href.indexOf('/real-estate/resources') > -1)
                {
                    setTimeout(window.scrollTo(0, 8000), 2000);
                }
                else
                {
                    window.location = '/real-estate/resources?frm=VendorBook';
                }
            }
            else
            {
                if((new URLSearchParams(location.search)).get('frm').toLowerCase() == 'virtualopenhouse')
                {
                    //TODO: Add logic to get current property virtual open house video
                }   
                else if(((new URLSearchParams(location.search)).get('frm').toLowerCase() == 'marketingvideo' || (new URLSearchParams(location.search)).get('frm').toLowerCase() == 'video') && (new URLSearchParams(location.search)).get('v'))
                {
                    var modalVideo = (new URLSearchParams(location.search)).get('v');
                    if(modalVideo.startsWith('PL'))
                    {
                        dispatch(openModalVideo(`https://www.youtube.com/embed?listType=playlist&list=${modalVideo}&modestbranding=1&autoplay=1&rel=0&fs=0`));
                    }
                    else
                    {
                        dispatch(openModalVideo(`https://www.youtube.com/embed/${modalVideo}?version=3&autoplay=1&rel=0&fs=0&modestbranding=1&mute=1`));
                    }
                }   
                else if((new URLSearchParams(location.search)).get('frm').toLowerCase() == 'newsletter')
                {
                    dispatch(setModal('marketnewsletter'));
                }                                               
                else
                {
                    dispatch(setModal((new URLSearchParams(location.search)).get('frm')));
                }
            }
        }  
        if((new URLSearchParams(location.search)).get('nav'))
        {
            //TODO: Enable nav hide for vTour ((!document.referrer.match(/^https?:\/\/([^\/]+\.)?resluxurygroup\.com(\/|$)/i) || (new URLSearchParams(location.search)).get('nav') === 'N') && (new URLSearchParams(location.search)).get('nav') !== 'Y') ? setShowMenu(false) : setShowMenu(true);
            (new URLSearchParams(location.search)).get('nav') === 'N' ? setShowMenu(false) : setShowMenu(true);
        }     
        if((new URLSearchParams(location.search)).get('Neighborhood'))
        {
            dispatch(setSubdivision((new URLSearchParams(location.search)).get('Neighborhood')));
        }    
        if((new URLSearchParams(location.search)).get('neighborhood'))
        {
            dispatch(setSubdivision((new URLSearchParams(location.search)).get('neighborhood')));
        }            
        if((new URLSearchParams(location.search)).get('Subdivision'))
        {
            dispatch(setSubdivision((new URLSearchParams(location.search)).get('Subdivision')));
        }   
        if((new URLSearchParams(location.search)).get('subdivision'))
        {
            dispatch(setSubdivision((new URLSearchParams(location.search)).get('subdivision')));
        }         
        if((new URLSearchParams(location.search)).get('PricePoint'))
        {
            dispatch(setPricePoint((new URLSearchParams(location.search)).get('PricePoint')));
        }                             
    }, [location]); 

    useEffect(() => {
        if(currentModal != '') {
             
            var newformValues = {};
            if(localStorage['formData'])
            {
                newformValues = JSON.parse(localStorage['formData']);
                newformValues['cs'] = 0;
                if(localStorage[currentModal])         
                {
                    newformValues = {...newformValues, ...JSON.parse(localStorage[currentModal]) };
                }
            }
            else
            {
                newformValues = {...formValues, cs: 0};
            }
            setFormValues(newformValues);   
        }
    }, [currentModal]);

    useEffect(() => {
        if(isSuccess)
        {
            dispatch(setResultHeader(htmlFrom(data?.ModalMessage?.Header)));
            dispatch(setResultBody(htmlFrom(data?.ModalMessage?.Body)));
            dispatch(setResultFooter(htmlFrom(data?.ModalMessage?.Footer)));
            if(currentModal === 'mortgage-calc')
            {
                var newformValues = {...formValues, cs: 1, term: 20, rate: 5.0};
                setFormValues(newformValues); 
                setValidated(false);
            }
            else
            {
                dispatch(setModal('results'));
            }
            if(data?.Action) (new Function(data?.Action))();
        }
    }, [data]);

    useEffect(() => {
        if(isError) 
        {
            setResultHeader(<Modal.Title>Error Encountered</Modal.Title>);
            setResultBody(<>This is very embarrassing, but I have encountered a problem while attempting to process your submission. I will have my development team look into the logs and resolve the issue. Thank you for your understanding! Feel free to contact us directly RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device' title='Click to automatically dial from mobile device'>352.794.1426</a></>);
            setResultFooter(<Button size='lg' variant='blue' onClick={()=>dispatch(setModal(''))}>Ok</Button>);
            dispatch(setModal('results'));
        }
    }, [isError]);

    useEffect(() => {
        window.addEventListener('resize', resizeEventHandler);

        function resizeEventHandler(event) {
            setWidth(window.innerWidth);
            //console.log(window.innerWidth);
        }
        
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener('resize', resizeEventHandler);
        };
    },[]);

    const MainForm = () => {                   
        return(
            <>
                <Row>
                    <Col sm='6'>
                        <Form.Group className='form-floating mb-3' controlId='fname' required>
                            <Form.Control type='text' placeholder='First name (Required)' maxLength={256} value={formValues['fname'] || ''} onChange={handleChange} required />
                            <Form.Label>First Name (Required)</Form.Label>
                            <Form.Control.Feedback type='invalid'>
                                Please provide first name.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm='6'>
                        <Form.Group className='form-floating mb-3' controlId='lname' required>
                            <Form.Control type='text' placeholder='Last name (Required)' maxLength={256} value={formValues['lname'] || ''} onChange={handleChange} required />
                            <Form.Label>Last Name (Required)</Form.Label>
                            <Form.Control.Feedback type='invalid'>
                                Please provide last name.
                            </Form.Control.Feedback>
                        </Form.Group>                    
                    </Col>
                </Row>
                <Row>
                    <Col sm='9'>
                        <Form.Group className='form-floating mb-3' controlId='email' required>
                            <Form.Control type='email' placeholder='Email (Required)' maxLength={256} value={formValues['email'] || ''} onChange={handleChange} required />
                            <Form.Label>Email (Required)</Form.Label>
                            <Form.Control.Feedback type='invalid'>
                                Please provide email address.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm='3'>
                        <Form.Group className='form-floating mb-3' controlId='mobile' required>
                            <Form.Control type='tel' placeholder='Mobile (Required)' value={formValues['mobile'] || ''} onChange={handleChange} pattern='^\d{3}-?\d{3}-?\d{4}$' required />
                            <Form.Label>Mobile (Required)</Form.Label>
                            <Form.Control.Feedback type='invalid'>
                                Please provide mobile phone number.
                            </Form.Control.Feedback>
                        </Form.Group>                    
                    </Col>
                </Row> 
            </>
        )
    }

    return (
        <>
            <Navbar bg='white' expand='md' fixed='top' className={showMenu ? 'exp-nav' : 'd-none exp-nav'} >
                <Container>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Brand href='/'>RES Luxury Group</Navbar.Brand>{/* TODO: Fix mobile Navbar.Brand layout */}
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto'>                            
                            <LinkContainer to='/' title='RES Luxury Group top producing eXp Realty team of central Florida' aria-label='RES Luxury Group top producing eXp Realty team of central Florida'>
                                <Nav.Link className='brand-home'>RES Luxury Group</Nav.Link>
                            </LinkContainer>  
                            <NavDropdown title={getWidth > 768 && getWidth < 992 ? 'Listings' : 'Property Listings'} id='basic-nav-dropdown'>  
                                <LinkContainer to='/homes-for-sale/Homosassa-Tradewinds-Condos/841532' aria-label='Home for sale at 10209 W Waterlander Loop #164, Homosassa, FL 34448' title='Home for sale in Homosassa, FL 34448'>
                                    <NavDropdown.Item>10209 W Waterlander Loop #164</NavDropdown.Item>
                                </LinkContainer>                             
                                <LinkContainer to='/homes-for-sale/Inverness-Highlands-North/840173' aria-label='Home for sale at 63 N Slator Ave, Inverness, FL 34453' title='Home for sale in Inverness, FL 34453'>
                                    <NavDropdown.Item>63 N Slator Ave</NavDropdown.Item>
                                </LinkContainer>                             
                                <LinkContainer to='/homes-for-sale/Inverness-Highlands-South/841132' aria-label='Home for sale at 1303 Claymore St, Inverness, FL 34450' title='Home for sale in Inverness, FL 34450'>
                                    <NavDropdown.Item>1303 Claymore St</NavDropdown.Item>
                                </LinkContainer>                                                                                                               
                                <LinkContainer to='/homes-for-sale/Citrus-Hills/840290' aria-label='Home for sale at 597 N Fresno Ave, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>
                                    <NavDropdown.Item>597 N Fresno Ave</NavDropdown.Item>
                                </LinkContainer>                              
                                <LinkContainer to='/homes-for-sale/Citrus-Springs/840011' aria-label='Home for sale at 1067 W Cary Dr, Citrus Springs, FL 34434' title='Home for sale in Citrus Springs, FL 34434'>
                                    <NavDropdown.Item>1067 W Cary Dr</NavDropdown.Item>
                                </LinkContainer>                              
                                <LinkContainer to='/homes-for-sale/Pinellas-County/839839' aria-label='Home for sale at 1710 Tallahassee Dr, Tarpon Springs, FL 34689' title='Home for sale in Tarpon Springs, FL 34689'>
                                    <NavDropdown.Item>1710 Tallahassee Dr</NavDropdown.Item>
                                </LinkContainer>                             
                                <LinkContainer to='/homes-for-sale/Marion-County/839664' aria-label='Home for sale at 8865 SW 157th Ln, Dunnellon, FL 34432' title='Home for sale in Dunnellon, FL 34432'>
                                    <NavDropdown.Item>8865 SW 157th Ln</NavDropdown.Item>
                                </LinkContainer>                              
                                <LinkContainer to='/land-for-sale/Brevard-County/839548' aria-label='Waterfront land for sale at 48 Sailview Cir, Titusville, FL 32780' title='Waterfront land for sale in Titusville, FL 32780'>
                                    <NavDropdown.Item>48 Sailview Cir</NavDropdown.Item>
                                </LinkContainer>                                                                                      
                                <LinkContainer to='/homes-for-sale/Citrus-Hills---Terra-Vista/838919' aria-label='Home for sale at 1251 W Skyview Crossing Dr, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>
                                    <NavDropdown.Item>1251 W Skyview Crossing Dr</NavDropdown.Item>
                                </LinkContainer>                                                                                        
                                <LinkContainer to='/homes-for-sale/Inverness-Highlands-West/836625' aria-label='Home for sale at 3957 S Ventura Ave, Inverness, FL 34452' title='Home for sale in Inverness, FL 34452'>
                                    <NavDropdown.Item>3957 S Ventura Ave</NavDropdown.Item>
                                </LinkContainer>                              
                                <LinkContainer to='/land-for-sale/Brevard-County/837205' aria-label='Waterfront land for sale at 5581 Constellation Dr, Titusville, FL 32780' title='Waterfront land for sale in Titusville, FL 32780'>
                                    <NavDropdown.Item>5581 Constellation Dr</NavDropdown.Item>
                                </LinkContainer>                              
                                <LinkContainer to='/land-for-sale/Brevard-County/837204' aria-label='Waterfront land for sale at 5962 Constellation Dr, Titusville, FL 32780' title='Waterfront land for sale in Titusville, FL 32780'>
                                    <NavDropdown.Item>5962 Constellation Dr</NavDropdown.Item>
                                </LinkContainer>                                                                                     
                                {/* <LinkContainer to='/land-for-sale/Citrus-Springs/836524' aria-label='Land for sale at 3607 W Parkview Dr, Dunnellon, FL 34433' title='Land for sale in Dunnellon, FL 34433'>
                                    <NavDropdown.Item>3607 W Parkview Dr</NavDropdown.Item>
                                </LinkContainer>                                                        */}
                                <LinkContainer to='/homes-for-sale/Citrus-Hills---Terra-Vista---Hunt-Club/835431' aria-label='Home for sale at 835 N Hunt Club Dr, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>
                                    <NavDropdown.Item>835 N Hunt Club Dr</NavDropdown.Item>
                                </LinkContainer>                                                             
                                <LinkContainer to='/land-for-sale/Citrus-Springs/835715' aria-label='Land for sale at 1277 E Cactus Ln, Dunnellon, FL 34434' title='Land for sale in Dunnellon, FL 34434'>
                                    <NavDropdown.Item>1277 E Cactus Ln</NavDropdown.Item>
                                </LinkContainer>                       
                                <LinkContainer to='/homes-for-sale/Citrus-Hills---Terra-Vista---Waterford-Place/835221' aria-label='Home for sale at 2321 N Hickory Glen Pt, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>
                                    <NavDropdown.Item>2321 N Hickory Glen Pt</NavDropdown.Item>
                                </LinkContainer>                                                                                     
                                <LinkContainer to='/homes-for-sale/Homosassa-Tradewinds-Condos/834757' aria-label='Home for sale at 10228 W Middleburg Loop #153, Homosassa, FL 34448' title='Home for sale in Homosassa, FL 34448'>
                                    <NavDropdown.Item>10228 W Middleburg Loop #153</NavDropdown.Item>
                                </LinkContainer> 
                                <LinkContainer to='/homes-for-sale/Homosassa-Tradewinds-Condos/834660' aria-label='Home for sale at 10204 W Waterlander Loop #159, Homosassa, FL 34448' title='Home for sale in Homosassa, FL 34448'>
                                    <NavDropdown.Item>10204 W Waterlander Loop #159</NavDropdown.Item>
                                </LinkContainer>                                                                                                  
                                <LinkContainer to='/homes-for-sale/Lakeside-Village/826539' aria-label='Home for sale at 3695 Laurelwood Loop, Beverly Hills, FL 34465' title='Home for sale in Beverly Hills, FL 34465'>
                                    <NavDropdown.Item>3695 Laurelwood Loop</NavDropdown.Item>
                                </LinkContainer>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                                {/* PENDINGS */} 
                                <LinkContainer to='/homes-for-sale/Citrus-Hills---Terra-Vista/840647' aria-label='Home for sale at 2045 N Lakecrest Loop, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>
                                    <NavDropdown.Item>2045 N Lakecrest Loop</NavDropdown.Item>
                                </LinkContainer>    
                                <LinkContainer to='/land-for-sale/Homosassa-Cos-Sub/837138' aria-label='Waterfront land for sale at 10708 W Halls River Rd, Homosassa, FL 34448' title='Waterfront land for sale in Homosassa, FL 34448'>
                                    <NavDropdown.Item>10708 W Halls River Rd</NavDropdown.Item>
                                </LinkContainer>                                                                                              
                                <LinkContainer to='/homes-for-sale/Citrus-Hills/839418' aria-label='Home for sale at 456 N Fresno Ave, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>
                                    <NavDropdown.Item>456 N Fresno Ave</NavDropdown.Item>
                                </LinkContainer>                                                                                               
                                <LinkContainer to='/land-for-sale/Marion-County/837699' aria-label='Land for sale at 0000 SW Hyacinth Ct, Dunnellon, FL 34434' title='Land for sale in Dunnellon, FL 34434'>
                                    <NavDropdown.Item>0000 SW Hyacinth Ct</NavDropdown.Item>
                                </LinkContainer>                                                                                                                                                                                       
                                {/* PENDINGS */}                                                                                       
                            </NavDropdown>
                            <NavDropdown title={getWidth > 768 && getWidth < 992 ? 'Search' : 'Propery Search'} id='basic-nav-dropdown'>
                                {/* <NavDropdown.Item href='https://exprealty.com/link/ruth_squires/6f64359e/' target='_blank' aria-label='Auction Properties in the US' title='Auction Properties in the US'>
                                    Auction Properties
                                </NavDropdown.Item>
                                <NavDropdown.Item href='https://exprealty.com/link/ruth_squires/679757a4/' target='_blank' aria-label='Foreclosure Homes in the US' title='Foreclosure Homes in the US'>
                                    Foreclosure Homes 
                                </NavDropdown.Item>     */}
                                <NavDropdown.Item href='https://ruthsquires.exprealty.com/' target='_blank' aria-label='Nationwide Homes Search in the US' title='Nationwide Homes Search in the US'>
                                    Search Homes Nationwide
                                </NavDropdown.Item>                                                                                                                              
                            </NavDropdown>                               
                            <NavDropdown title={getWidth > 768 && getWidth < 992 ? 'Area' : 'Our Area'} id='basic-nav-dropdown'>
                                <LinkContainer to='/florida-community/pine-ridge' title='Learn more about Pine Ridge Estates' aria-label='Learn more about Pine Ridge Estates'>
                                    <NavDropdown.Item>Pine Ridge</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/florida-community/terra-vista' title='Learn more about Citrus Hills - Terra Vista' aria-label='Learn more about Citrus Hills - Terra Vista'>
                                    <NavDropdown.Item>Terra Vista</NavDropdown.Item>
                                </LinkContainer>                                
                                <LinkContainer to='/florida-community/tradewinds-resort' title='Learn more about TradeWinds Resort' aria-label='Learn more about TradeWinds Resort'>
                                    <NavDropdown.Item>TradeWinds Resort</NavDropdown.Item>
                                </LinkContainer>       
                                <LinkContainer to='/homes-for-sale/citrus-hills' title='Learn more about Citrus Hills' aria-label='Learn more about Citrus Hills'>
                                    <NavDropdown.Item>Citrus Hills</NavDropdown.Item>
                                </LinkContainer>        
                                <LinkContainer to='/homes-for-sale/canterbury-lake-estates' title='Learn more about Canterbury Lake Estates' aria-label='Learn more about Canterbury Lake Estates'>
                                    <NavDropdown.Item>Canterbury Lake Estates</NavDropdown.Item>
                                </LinkContainer>                                                                   
                                <LinkContainer to='/florida-community/crystal-oaks' title='Learn more about Crystal Oaks' aria-label='Learn more about Crystal Oaks'>
                                    <NavDropdown.Item>Crystal Oaks</NavDropdown.Item>
                                </LinkContainer>        
                                <LinkContainer to='/homes-for-sale/citrus-springs' title='Learn more about Citrus Springs' aria-label='Learn more about Citrus Springs'>
                                    <NavDropdown.Item>Citrus Springs</NavDropdown.Item>
                                </LinkContainer> 
                                <LinkContainer to='/homes-for-sale/meadowcrest' title='Learn more about Meadowcrest' aria-label='Learn more about Meadowcrest'>
                                    <NavDropdown.Item>Meadowcrest</NavDropdown.Item>
                                </LinkContainer>                                                                                                  
                            </NavDropdown>   
                            <NavDropdown title={getWidth > 768 && getWidth < 1150 ? 'Resource' : 'Buying / Selling Resources'} id='basic-nav-dropdown'>     
                                <LinkContainer to={{pathname:'/real-estate/resources', search:'frm=marketnewsletter'}} title='Real Estate Newsletter Sign Up' aria-label='Real Estate Newsletter Sign Up' activeClassName=''>
                                    <NavDropdown.Item>Newsletter Sign Up</NavDropdown.Item>
                                </LinkContainer>    
                                <LinkContainer to={{pathname:'/real-estate/resources', search:'frm=mortgage-calc'}} title='Mortgage Calculator' aria-label='Mortgage Calculator' activeClassName=''>
                                    <NavDropdown.Item>Mortgage Calculator</NavDropdown.Item>
                                </LinkContainer>                                  
                                {/* <LinkContainer to='/real-estate/resources?frm=eventnotification' title='Real Estate Event Notifications' aria-label='Real Estate Event Notifications'>
                                    <NavDropdown.Item>Event Notifications</NavDropdown.Item>
                                </LinkContainer>    */}
                                <LinkContainer to='/real-estate/book' title='RES Luxury Group Real Estate Real Estate Book - Selling Secrets' aria-label='RES Luxury Group Real Estate Real Estate Book - Selling Secrets'>
                                    <NavDropdown.Item>Our Book</NavDropdown.Item>
                                </LinkContainer>    
                                <LinkContainer to='/real-estate/market-stats' title='Real Estate Market Housing Stats' aria-label='Real Estate Market Housing Stats'>
                                    <NavDropdown.Item>Housing Market Stats</NavDropdown.Item>
                                </LinkContainer>                                                                                                  
                                <LinkContainer to='/real-estate/realtor-on-demand' title='Realtor On Demand' aria-label='Realtor On Demand'>
                                    <NavDropdown.Item>Realtor On Demand</NavDropdown.Item>
                                </LinkContainer>     
                                {/* <LinkContainer to='/real-estate/best-buy-homes-list' title='Florida Best Buy Homes List' aria-label='Florida Best Buy Homes List'>
                                    <NavDropdown.Item>Best Buy Homes List</NavDropdown.Item>
                                </LinkContainer>                                   */}
                                <LinkContainer to='/real-estate/hometown-heroes' title='Learn more about the Hometown Heroes Program' aria-label='Learn more about the Hometown Heroes Program'>
                                    <NavDropdown.Item>Hometown Heroes</NavDropdown.Item>
                                </LinkContainer> 
                                {/* <LinkContainer to='/real-estate/relocation' title='Relocation Resources' aria-label='Relocation Resources'>
                                    <NavDropdown.Item>Relocation Resources</NavDropdown.Item>
                                </LinkContainer>                                   */}
                                <LinkContainer to='/real-estate/vendors' title='Preferred Vendors' aria-label='Preferred Vendors'>
                                    <NavDropdown.Item>Preferred Vendors</NavDropdown.Item>
                                </LinkContainer>                                                                   
                                <LinkContainer to='/real-estate/resources' title='More Real Estate Resources' aria-label='More Real Estate Resources'>
                                    <NavDropdown.Item>More Resources</NavDropdown.Item>
                                </LinkContainer>                                                                                                                                                                 
                            </NavDropdown>  
                            <NavDropdown title={getWidth > 768 && getWidth < 992 ? 'About' : 'About Us'} id='basic-nav-dropdown'>
                                <LinkContainer to='/real-estate/reviews' title='RES Luxury Group Customer Reviews' aria-label='RES Luxury Group Customer Reviews'>
                                    <NavDropdown.Item>Customer Reviews</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to='/real-estate/contacts' title='Contact RES Luxury Group' aria-label='Contact RES Luxury Group'>
                                    <NavDropdown.Item>Contact Us</NavDropdown.Item>
                                </LinkContainer>                                                                                                                                
                            </NavDropdown>                                                                     
                        </Nav>
                    </Navbar.Collapse>
                    <Image fluid='true'
                    src={logo} 
                    alt='eXp Realty'
                    className='broker-logo d-inline-block align-top'
                    />{' '}
                </Container>
            </Navbar>  
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'feedback'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Your feedback is very important.</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to summarize your experience.</p>
                    <Form.Group className='form-floating mb-3' controlId='feedback'>
                        <Form.Control as='textarea' placeholder='Enter feedback' maxLength={256} rows={4} value={formValues['feedback'] || ''} onChange={handleChange} required />
                        <Form.Label>Enter Feedback (Required)</Form.Label>
                        <Form.Control.Feedback type='invalid'>
                            Please provide feedback.
                        </Form.Control.Feedback>                        
                    </Form.Group>
                    <Form.Group className='form-floating mb-3 col-md-6' controlId='fullname' required>
                        <Form.Control type='text' placeholder='Full name' maxLength={256} value={formValues['fullname'] || ''} onChange={handleChange} required />
                        <Form.Label>Full Name (Required)</Form.Label>
                        <Form.Control.Feedback type='invalid'>
                            Please provide full name.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Submit <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='FeedBack' />
                </Form>
            </Modal>
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'video'} onHide={() => dispatch(setModal(''))}>
            <Modal.Header className='bg-black res-font' closeVariant='white' closeButton><div className='res-modal-title'>RES Luxury Group</div></Modal.Header>
                <Ratio aspectRatio='16x9'>
                    <iframe className='w-100' src={modalVideoUrl} allowFullScreen allow='autoplay'></iframe>
                </Ratio>
            </Modal>          
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'results'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    {resultHeader}
                </Modal.Header>
                <Modal.Body>
                    {resultBody}
                </Modal.Body>
                <Modal.Footer>
                    {resultFooter}
                </Modal.Footer>
            </Modal>
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'marketnewsletter'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Subscribe to stay up to date!</Modal.Title>
                </Modal.Header>
                <Form action='https://mlscitrus.us20.list-manage.com/subscribe/post?u=3b4dbb66e4fc29b28043a871a&amp;id=8da6ad4bc4' method='post' id='mc-embedded-subscribe-form' noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to receive our newsletter.</p>
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='mce-FNAME' required>
                                <Form.Control data-type='mce' name='FNAME' type='text' placeholder='First name (Required)' maxLength={256} value={formValues['fname'] || ''} onChange={handleChange} required />
                                <Form.Label>First Name (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='mce-LNAME' required>
                                <Form.Control data-type='mce' name='LNAME' type='text' placeholder='Last name (Required)' maxLength={256} value={formValues['lname'] || ''} onChange={handleChange} required />
                                <Form.Label>Last Name (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide last name.
                                </Form.Control.Feedback>
                            </Form.Group>                    
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='9'>
                            <Form.Group className='form-floating mb-3' controlId='mce-EMAIL' required>
                                <Form.Control data-type='mce' name='EMAIL' type='email' placeholder='Email (Required)' maxLength={256} value={formValues['email'] || ''} onChange={handleChange} required />
                                <Form.Label>Email (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm='3'>
                            <Form.Group className='form-floating mb-3' controlId='mobile' required>
                                <Form.Control data-type='mce-phone' type='tel' placeholder='Mobile (Required)' value={formValues['mobile'] || ''} onChange={handleChange} pattern='^\d{3}-?\d{3}-?\d{4}$' required />
                                <Form.Label>Mobile (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide mobile phone number.
                                </Form.Control.Feedback>
                            </Form.Group>                    
                        </Col>
                    </Row>        
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading} id='mc-embedded-subscribe'>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Subscribe <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>                                     
                </Modal.Footer>
                    <input id='mce-PHONE-area' name='PHONE[area]' value={formValues['phone-area']} type='hidden' />
                    <input id='mce-PHONE-detail1' name='PHONE[detail1]' value={formValues['phone-detail1']} type='hidden' />
                    <input id='mce-PHONE-detail2' name='PHONE[detail2]' value={formValues['phone-detail2']} type='hidden' />
                    <input type='hidden' name='b_3b4dbb66e4fc29b28043a871a_8da6ad4bc4' value='' />
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='MarketNewsletter' />
                </Form>
            </Modal>            
            {/* Multi-part Forms */}
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'mortgage-calc'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Find out how much you can afford now!</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formCalc}>
                <Modal.Body>
                    <div className={formValues['cs']<1 ? '' : 'd-none'}>
                        <p>Please complete the form below to gain instant access to our realtime mortgage calculator.</p>
                        {MainForm()}  
                    </div>      
                    <div className={formValues['cs']==1 ? '' : 'd-none'}>
                        <p>Use this mortgage calculator to figure out what kind of house you can afford. There are two ways to use this calculator:</p>
                        <p>1. Enter the amount of a mortgage in the "Mortgage Amount" field and click on the "Calculate Payment" button to determine the monthly payments for the home.</p>
                        <p>2. Enter the amount of monthly payment you want to pay in the "Monthly Payments" field and click on the "Calculate Mortgage" button to determine the mortgage you can afford.</p>
                        <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='amount' required={formValues['cs']==1}>
                                <Form.Control type='text' placeholder='Mortgage Amount' value={formValues['amount'] || ''} onChange={handleChange} pattern='^\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$'/>
                                <Form.Label>Mortgage Amount</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide valid mortgage amount to the nearest cent.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='payments' required={formValues['cs']==1}>
                                <Form.Control type='text' placeholder='Length of Term' value={formValues['payments'] || ''} onChange={handleChange} pattern='^\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$'/>
                                <Form.Label>Estimated Monthly Payments</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide valid monthly payments to the nearest cent.
                                </Form.Control.Feedback>
                            </Form.Group>                    
                        </Col>
                        </Row>                          
                        <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='rate' required={formValues['cs']==1}>
                                <Form.Control type='text' placeholder='Interest Rate (Required)' maxLength={8} value={formValues['rate'] || ''} onChange={handleChange} pattern='^(([0-9]*)|(([0-9]*)\.([0-9]*)))$'/>
                                <Form.Label>Interest Rate</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide valid interest rate.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='term' required={formValues['cs']==1}>
                                <Form.Control type='text' placeholder='Length of Term (Required)' maxLength={8} value={formValues['term'] || ''} onChange={handleChange} pattern='^(([0-9]*)|(([0-9]*)\.([0-9]*)))$'/>
                                <Form.Label>Length of Term (years)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide valid length of term in years.
                                </Form.Control.Feedback>
                            </Form.Group>                    
                        </Col>
                        </Row>                         
                    </div>                                                                                                     
                </Modal.Body>
                <Modal.Footer>
                    <div className={formValues['cs']<1 ? '' : 'd-none'}>
                        <Stack gap={2} direction='horizontal'>
                            <div className='me-auto'>
                                <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                            </div>    
                            <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                                {isLoading ? 
                                <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                                :
                                <>{formValues['cs']<1 ? 'Get Access Now' : formValues['cs']<2 ? 'Next' : 'Submit'} <i role='presentation' className='bi bi-caret-right-fill'></i></>
                                }
                            </Button> 
                        </Stack> 
                    </div>   
                    <div className={formValues['cs']==1 ? '' : 'd-none'}>   
                        <Stack gap={2} direction='horizontal'>
                            <Button size='lg' variant='blue' type='button' onClick={() => CalcMortgage('payment')}>
                                <>Calculate Payment <i role='presentation' className='bi bi-calculator'></i></>
                            </Button> 
                            <Button size='lg' variant='blue' type='button' onClick={() => CalcMortgage('amount')}>
                                <>Calculate Mortgage <i role='presentation' className='bi bi-calculator'></i></>
                            </Button>       
                            <Button size='lg' variant='secondary' type='button' onClick={() => CalcMortgage('reset')}>
                                <>Reset <i role='presentation' className='bi bi-x-square'></i></>
                            </Button>  
                        </Stack>                  
                    </div>                                    
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='mortgage-calc' />
                </Form>
            </Modal>                    
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'justsold'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>FREE report of ALL “Just Sold” homes in YOUR neighborhood!</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className={formValues['cs']<1 ? '' : 'd-none'}>
                        <p>Please complete the form below to gain access to your FREE “Just Sold” report.</p>
                        <Row>
                            <Col>
                                <Form.Group className='form-floating mb-3' controlId='neighborhood'>
                                    <Form.Control type='text' placeholder='Neighborhood/subdivision (Required)' maxLength={256} value={formValues['neighborhood'] || ''} onChange={handleChange} required={formValues['cs']<1}  />
                                    <Form.Label>Neighborhood/subdivision (Required)</Form.Label>
                                    <Form.Control.Feedback type='invalid'>
                                        Please provide neighborhood/subdivision.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>                    
                        <Row>
                            <Col sm='9'>
                                <Form.Group className='form-floating mb-3' controlId='email'>
                                    <Form.Control type='email' placeholder='Email (Required)' maxLength={256} value={formValues['email'] || ''} onChange={handleChange} required={formValues['cs']<1} />
                                    <Form.Label>Email (Required)</Form.Label>
                                    <Form.Control.Feedback type='invalid'>
                                        Please provide email address.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>       
                    </div>      
                    <div className={formValues['cs']==1 ? '' : 'd-none'}>
                        <p>Are you currently working with a Realtor?</p>
                        <Row>
                            <Col>
                                <Form.Group className='form-floating mb-3' controlId='realtor' required >
                                    <Form.Select aria-label='Select realtor status' value={formValues['realtor'] || ''} onChange={handleChange} required={formValues['cs']==1} >
                                        <option value=''></option>
                                        <option value='Y'>Yes, I have a realtor</option>
                                        <option value='N'>No, I do not have a realtor</option>
                                    </Form.Select> 
                                    <Form.Label>Select realtor status</Form.Label>                                   
                                    <Form.Control.Feedback type='invalid'>
                                        Please select realtor status.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>                         
                    </div>       
                    <div className={formValues['cs']==2 ? '' : 'd-none'}>
                        <p>What is your current situation?</p>
                        <Row>
                            <Col>
                                <Form.Group className='form-floating mb-3' controlId='situation' required >
                                    <Form.Select aria-label='Select situation' value={formValues['situation'] || ''} onChange={handleChange} required={formValues['cs']==2} >
                                        <option value=''></option>
                                        <option value='SELL'>Need to sell my home</option>
                                        <option value='BUY'>Need to buy a home</option>
                                        <option value='BOTH'>Need to sell and buy</option>
                                    </Form.Select>                 
                                    <Form.Label>Select situation</Form.Label>                         
                                    <Form.Control.Feedback type='invalid'>
                                        Please select situation.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>                         
                    </div>     
                    <div className={formValues['cs']==3 ? '' : 'd-none'}>
                        <p>When are you thinking of selling or buying?</p>
                        <Row>
                            <Col>
                                <Form.Group className='form-floating mb-3' controlId='timeframe' required >
                                    <Form.Select aria-label='Select timeframe' value={formValues['timeframe'] || ''} onChange={handleChange} required={formValues['cs']==3} >
                                        <option value=''></option>
                                        <option value='0-30'>0-30 days</option>
                                        <option value='31-90'>31-90 days</option>
                                        <option value='180+'>Over 6 months</option>
                                        <option value='THINKING'>Just in the 'thinking about it' stage right now</option>
                                    </Form.Select>  
                                    <Form.Label>Select timeframe</Form.Label>                                   
                                    <Form.Control.Feedback type='invalid'>
                                        Please select situation.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>                         
                    </div>     
                    <div className={formValues['cs']==4 ? '' : 'd-none'}>
                        <p>If you'd like a detailed report about the current value of your property, enter the address here. (Optional but recommended)</p>
                        <Row>
                            <Col>
                                <Form.Group className='form-floating mb-3' controlId='address'>
                                    <Form.Control type='text' placeholder='Property Address' maxLength={256} value={formValues['address'] || ''} onChange={handleChange} />
                                    <Form.Label>Property Address</Form.Label>                                    
                                </Form.Group>
                            </Col>
                        </Row>                          
                    </div>                                                                                                
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>{formValues['cs']<1 ? 'Get Access Now' : formValues['cs']<4 ? 'Next' : 'Submit'} <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>                                    
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={5} />
                    <input type='hidden' id='sto' name='sto' value={formValues['situation'] === 'SELL' ? 'ruthlead' : 'leadpool'} />
                    <input type='hidden' id='cta' name='cta' value='JustSold' />
                </Form>
            </Modal> 
            {/* Basic Forms */}
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'closingvoucher'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>$500 Coupon</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to receive your voucher.</p>
                    {MainForm()} 
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>   
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Request Voucher <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>                                     
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='ClosingVoucher' />
                </Form>
            </Modal>   
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'eventnotification'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Stay informed of upcoming events!</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to receive notification of upcoming events.</p>
                    {MainForm()}              
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get Events <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='EventNotification' />
                </Form>
            </Modal>   
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'freebook'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Request free access to our book:<br/><i>'Selling Secrets You Can't Afford to Miss'</i></Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to gain access to our book.</p>
                    {MainForm()}                
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get Access <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button> 
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='FreeBook' />
                </Form>
            </Modal> 
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'ratesvideo'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Request free access to how people can get 2-3% Mortgage Rates</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to gain access to low mortgage rate secrets.</p>
                    {MainForm()}                
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get Access <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button> 
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='RatesVideo' />
                </Form>
            </Modal>             
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'bestbuylist'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>RES Luxury Group - Real Estate Best Buy List</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Stay informed of all homes for sale that are listed for $75 per sq. ft or less.</p>
                    {MainForm()}                 
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get Info <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button> 
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='BestBuyList' />
                </Form>
            </Modal>  
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'hth-access'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Request free access to our Hometown Heroes program details</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    {MainForm()}                
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get Accesss <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button> 
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='HTH' />
                </Form>
            </Modal>      
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'hth-qa'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Register for a free Hometown Heroes program Q&amp;A consult</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    {MainForm()}                
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Register Now <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button> 
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='HTH-QA' />
                </Form>
            </Modal>     
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'tradewinds-rsvp'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Please accept my RSVP to the TradeWinds Community Tour and keep me updated!</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    {MainForm()}                 
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Register Now <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button> 
                </Modal.Footer>
                    <input type='hidden' id='neighborhood' name='neighborhood' value='TradeWinds-Resort' />
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='TradeWinds-RSVP' />
                    <input type='hidden' id='sto' name='sto' value='admin' />
                </Form>
            </Modal> 
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'chbuildershowcasehomes'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>RES Luxury Group - Off-market showcase homes</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Feel free to complete the form below to request your exclusive list of off-market Terra Vista homes.</p>
                    {MainForm()}                 
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Request Access <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button> 
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='CHBuilderShowcaseHomes' />
                </Form>
            </Modal>            
            {/* Basic Forms + Additional field */}
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'homeslist'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Properties for sale{subdivision ? ` in ${subdivision}` : ` list`}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to request your FREE list of {subdivision} properties for sale.</p>
                    {MainForm()}
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='neighborhood' required>
                                <Form.Control type='text' placeholder='Subdivision (Required)' maxLength={256} value={formValues['neighborhood'] || `${subdivision}`} onChange={handleChange} required />
                                <Form.Label>Subdivision (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide the subdivision that you wish to receive listings on.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col> 
                    </Row>                                                          
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get List Now <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='HomesList' />
                </Form>
            </Modal>
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'speciallist'}>
                <Modal.Header>
                    <Modal.Title>Special {subdivision ? ` ${subdivision} property list` : ` property list`}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to be granted access to your FREE {subdivision} list of homes for sale.</p>
                    {MainForm()}
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='neighborhood' required>
                                <Form.Control type='text' placeholder='Property Type (Required)' maxLength={256} value={formValues['neighborhood'] ? formValues['neighborhood'] : subdivision} onChange={handleChange} required readOnly />
                                <Form.Label>Property Type (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide the subdivision that you wish to receive listings on.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col> 
                    </Row>                                                          
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get List Now <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='SpecialList' />
                </Form>
            </Modal>            
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'offmarket-terravista'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Enjoy browsing our Terra Vista community resource page.</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Feel free to complete the form below to request your exclusive list of off-market Terra Vista homes.</p>
                    {MainForm()} 
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='neighborhood' required>
                                <Form.Control type='text' placeholder='Subdivision (Required)' maxLength={256} value={subdivision ? `${subdivision}` : 'Citrus Hills - Terra Vista'} onChange={handleChange} required />
                                <Form.Label>Subdivision (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide the subdivision that you wish to receive listings on.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col> 
                    </Row>                                                          
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Get List Now <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='offmarket-TerraVista' />
                </Form>
            </Modal>      
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'cashinvestor'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Sell your house for CASH NOW!</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>We have cash investors looking for homes today... Interested?<br />Complete this form and we will reach out to discuss your specific needs.<br />Also feel free to call or text RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device' title='Click to automatically dial from mobile device'>352.794.1426</a>.</p>
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='address' required>
                                <Form.Control type='text' placeholder='Property Address (Required)' maxLength={256} value={formValues['address'] || ''} onChange={handleChange} required />
                                <Form.Label>Property Address (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide property address.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>                    
                    {MainForm()}                
                </Modal.Body>
                <Modal.Footer>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not<p className='indent'>&nbsp;share or resell your information.</p>
                    </div>    
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Contact me about cash buyers <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>                                    
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='sto' name='sto' value='ruthlead' />
                    <input type='hidden' id='cta' name='cta' value='CashInvestor' />
                </Form>
            </Modal>     
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'propertyanalysis'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>YES, analyze my{subdivision ? ` ${subdivision}` : ``} property value...</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>and send me alerts whenever properties{subdivision ? ` in ${subdivision}` : ``} are listed and sold, so I always know the value.<br />Please complete the form below to receive your property analysis.</p>
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='address' required>
                                <Form.Control type='text' placeholder='Property Address (Required)' maxLength={256} value={formValues['address'] || ''} onChange={handleChange} required />
                                <Form.Label>Property Address (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide property address.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>                    
                    {MainForm()}                 
                </Modal.Body>
                <Modal.Footer>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Email Me My Property Value Analysis &amp; Start My Alerts <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>                  
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                       
                </Modal.Footer>
                    <input type='hidden' id='neighborhood' name='neighborhood' value={subdivision} />
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='PropertyAnalysis' />
                    <input type='hidden' id='sto' name='sto' value='ruthlead' />
                </Form>
            </Modal>                                                         
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'relocationbrochure'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to Citrus County Relocation Brochure</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to be mailed your free relocation brochure.</p>
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='address' required>
                                <Form.Control type='text' placeholder='Property Address (Required)' maxLength={256} value={formValues['address'] || ''} onChange={handleChange} required />
                                <Form.Label>Property Address (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide property address.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>                    
                    {MainForm()}                
                </Modal.Body>
                <Modal.Footer>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Send Request <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>                  
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                       
                </Modal.Footer>
                    <input type='hidden' id='neighborhood' name='neighborhood' value={subdivision} />
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='relocationbrochure' />
                </Form>
            </Modal>    
            <Modal size='lg' fullscreen='md-down' centered={true} show={currentModal === 'ftbseminar'} onHide={() => dispatch(setModal(''))}>
                <Modal.Header closeButton>
                    <Modal.Title>First Time Homebuyer Seminar</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Please complete the form below to be register to attend RES Luxury Group's FREE First Time Homebuyer event.</p>
                    <p>Space is limited so we have provided three (3) time slots, reserve your place now before it fulls up.</p>                  
                    {MainForm()}  
                    <Row>
                        <Col>
                            <Form.Group className='form-floating mb-3' controlId='situation' required>
                                <Form.Control type='text' placeholder='Please provide occupation & employer (Required)' maxLength={256} value={formValues['situation'] || ''} onChange={handleChange} required />
                                <Form.Label>Please provide occupation & employer (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide occupation & employer.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>   
                    <Row>
                        <Col>
                            <Form.Group className='mb-3' controlId='timeframe' required >
                                <Form.Select aria-label='Select session' value={formValues['timeframe'] || ''} onChange={handleChange} required >
                                    <option value=''>Select session</option>
                                    <option value='9am'>9:00 a.m.</option>
                                    <option value='10am'>10:00 a.m.</option>
                                    <option value='11am'>11:00 a.m.</option>
                                </Form.Select>                                    
                                <Form.Control.Feedback type='invalid'>
                                    Please select session.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>                                                           
                </Modal.Body>
                <Modal.Footer>
                    <Button size='lg' variant='blue' type='submit' disabled={isLoading}>
                        {isLoading ? 
                        <>Saving <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                        :
                        <>Register <i role='presentation' className='bi bi-caret-right-fill'></i></>
                        }
                    </Button>                  
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>                       
                </Modal.Footer>
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='ftbseminar' />
                    <input type='hidden' id='sto' name='sto' value='all' />
                </Form>
            </Modal>                                      
        </>
    );
}

export default MainMenu;